( function ( $ ) {
    'use strict';
    // Strict mode JS

    var phoneBreakpoint = 750;

    $( document ).ready( function () {
    	var secondaryMenuItems= $('.navbar.secondary .nav > li');
    	$(window).on('load resize', function () {
    		if( isPhone() ) {
    			$('.logo').insertBefore('.navbar-header-buttons');
    			secondaryMenuItems.appendTo('.main.navbar .nav:not(.pull-right)');
                if( $('.sidebar').length ) {
                    $('.sidebar').appendTo('.mobile-sidebar');
                }

                if( $('.top-footer .social-icons-widget').length ) {
                    $('.social-icons-widget').prependTo('.bottom-footer .container > .row');
                }
    		} else {
    			secondaryMenuItems.appendTo('.navbar.secondary .nav');
    			$('.logo').prependTo('.bottom-header .container .row');

                if( $('.mobile-sidebar .sidebar').length ) {
                    $('.mobile-sidebar .sidebar').prependTo('.main-section-inner > .row');
                }

                if( $('.bottom-footer .social-icons-widget').length ) {
                    $('.social-icons-widget').prependTo('.top-footer .container > .row');
                }
            }
            handleReferentiesGrid();
    	});

    	$('.slider').slick({
    		accessibility: false,
    		autoplay: true,
    		arrows: false,
    		dots: true,
            // appendDots: '.slide .caption',
    		draggable: false,
			infinite: true,
            mobileFirst: true,
			speed: 500,
			fade: true,
			cssEase: 'linear',
			swipe: false
    	});

        if( $('.photo-gallery').length ) {
            changeProductDescription();
            $('.product-thumbnail .product-link').click(function(e) {
                e.preventDefault();
                $('.product-thumbnail').each(function() {
                    if( $(this).hasClass('active') ) {
                        $(this).removeClass('active');
                    }
                });
                $(this).parent().addClass('active');
                changeProductDescription();
            });
        }
    });

    // Helper functions
    function isMobile() {
    	 //initiate as false
    	var isMobile = false;
    	// device detection
    	if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    		    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
    		isMobile = true;
    	return isMobile;
    }

    function isPhone() {
    	//phone width check
    	var isPhone = false;
    	if ($(window).width() < phoneBreakpoint) {
        // if ($(window).width() < 750) {
    		isPhone = true;
    	}
    	return isPhone;
    }

    function initMap() {
        var ogCoord = {lat: 51.582912, lng: 4.7555};
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 17,
            center: ogCoord,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false
        });
        var marker = new google.maps.Marker({
            position: ogCoord,
            map: map
        });
    }

    function changeProductDescription() {
        $('.product-thumbnail').each(function() {
            if($(this).hasClass('active')) {
                var productLink = $(this).children('.product-link').attr('href');
                var productTitle = $(this).children('.product-link').data('product-title');
                var productType = $(this).children('.product-link').data('product-type');

                $('.description .product-title').text(productTitle);
                $('.description .product-type').text(productType);
                $('.btn-product-more').attr('href', productLink);
            }
        });
    }

    // Referenties page
    function handleReferentiesGrid() {
        if( $('body.referenties').length ) {
            console.log('Referenties page');
            var galleryItems = $('.gallery-item');
            // console.log(isPhone());
            //Phone
            if( isPhone() ) {
                // Reset on resize
                galleryItems.each(function() {
                    $(this).removeClass('gallery-item__expanded');
                    $(this).removeAttr('style');
                    $(this).find('.caption:first-child').removeClass('invisible');
                    $(this).find('.expanded-caption').addClass('hidden');
                    $(this).find('.gallery-item__expand').addClass('hidden-xs');
                });
                // Start slideshows on mobile
                $('.gallery-item__slideshow').each(function(){
                    $(this).slick({
                        accessibility: false,
                        autoplay: false,
                        arrows: false,
                        dots: true,
                        draggable: false,
                        infinite: true,
                        mobileFirst: true,
                        speed: 500,
                        fade: true,
                        cssEase: 'linear',
                        swipe: true,
                        responsive: [{
                              // breakpoint: 750,
                              breakpoint: phoneBreakpoint,
                              settings: 'unslick'
                            }]
                    });
                });
                // Event handler
                $('.btn-toggle-gallery-item, .gallery-item .gallery-item__slideshow img').unbind('click').bind('click', function(e) {
                    e.preventDefault();
                    var // galleryItems = $('.gallery-item'),
                        galleryItem = $(this).closest('.gallery-item'),
                        galleryItemExpand = galleryItem.children('.gallery-item__expand'),
                        galleryItemSlideshow = galleryItem.find('.gallery-item__slideshow');

                    //resetting the items
                    galleryItems.each(function() {
                        if( $(this).hasClass('gallery-item__expanded') ){
                            $(this).removeClass('gallery-item__expanded');
                            $(this).find('.gallery-item__expand').addClass('hidden-xs');
                        }
                    });

                    galleryItemExpand.removeClass('hidden-xs');
                    galleryItem.addClass('gallery-item__expanded');
                });
            } else {
                //TODO: Reset on resize
                galleryItems.each( function() {
                    if( $(this).hasClass('gallery-item__expanded') ) {
                        $(this).removeClass('gallery-item__expanded');
                    }
                });
                //Event handler
                $('.btn-toggle-gallery-item, .gallery-item .gallery-item__slideshow img').unbind('click').bind('click', function(e) {
                    e.preventDefault();
                    var //galleryItems = $('.gallery-item'),
                        galleryItem = $(this).closest('.gallery-item'),
                        galleryItemExpand = galleryItem.children('.gallery-item__expand'),
                        galleryItemSlideshow = galleryItem.find('.gallery-item__slideshow'),
                        galleryItemCaption = galleryItem.find('.caption:first-child'),
                        galleryItemExpandedCaption = galleryItem.find('.expanded-caption'),
                        galleryItemLightboxToggle = galleryItem.find('.btn-toggle-fullscreen');

                    var clickedidx=$('.gallery-item').index($(galleryItem)),
                        clrow = parseInt( clickedidx /3 ),
                        prevselected  = false;
                    // console.log(' * '+clickedidx+' '+clrow);

                    //resetting the items
                    galleryItems.each(function(idx) {
                        if( $(this).hasClass('gallery-item__expanded') ){
                            prevselected = $(this);
                            var exprow = parseInt(idx / 3);
                            // console.log(idx+' '+exprow);
                            if( exprow!=clrow ){
                                $(this).removeClass('gallery-item__expanded');
                                // $(this).css('margin-bottom', 0);
                                $(this).animate({ marginBottom: '0px'}, 500);
                                $(this).find('.gallery-item__slideshow').slick('unslick');
                                $(this).find('.gallery-item__slideshow li').addClass('hidden').removeAttr('style');
                                $(this).find('.caption').removeClass('invisible');
                                $(this).find('.expanded-caption').addClass('hidden');
                                }else{
                                $(this).removeClass('gallery-item__expanded');
                                // $(this).find('.gallery-item__expand').addClass('hidden');
                                $(this).find('.gallery-item__slideshow').slick('unslick');
                                $(this).find('.gallery-item__slideshow li').addClass('hidden').removeAttr('style');
                                $(this).find('.caption').removeClass('invisible');
                                $(this).find('.expanded-caption').addClass('hidden');
                            }
                        }
                    });

                    galleryItem.addClass('gallery-item__expanded');
                    $(galleryItem).animate({ marginBottom: galleryItemExpand.outerHeight()+'px'}, 500, function(){
                        if( prevselected && !$(prevselected).hasClass('gallery-item__expanded') ) {
                            $(prevselected).animate({ marginBottom: '0px'}, 500);
                        }
                    });
                    galleryItemCaption.addClass('invisible');
                    galleryItemExpandedCaption.removeClass('hidden');

                    galleryItemSlideshow.slick({
                        accessibility: false,
                        autoplay: false,
                        arrows: false,
                        dots: true,
                        draggable: false,
                        infinite: true,
                        // mobileFirst: false,
                        speed: 500,
                        fade: true,
                        cssEase: 'linear',
                        swipe: false,
                        responsive: [{
                              // breakpoint: 750,
                              breakpoint: phoneBreakpoint,
                              settings: 'unslick'
                            }]
                    });
                    galleryItemSlideshow.find('li').removeClass('hidden');

                    // TODO: add fullscreen button functionality
                    galleryItemLightboxToggle.click(function() {
                    });
                });
            }
        }
    }
}(jQuery));
